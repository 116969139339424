import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <section className="pb-0 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Blog</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Category</a>
                    </li>
                  </ol>
                </nav>
                {/*<span className="badge bg-primary-alt text-primary">
                  <img className="icon bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />21
                </span>*/}
              </div>
              <h1>{title}</h1>
              <div className="d-flex align-items-center">
                <a href="#">
                  <img src="assets/img/avatars/male-1.jpg" alt="Avatar" className="avatar mr-2" />
                </a>
                <div>
                  <div>by <a href="#">Benjamin Cameron</a>
                  </div>
                  <div className="text-small text-muted">30th October</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0" data-reading-position>
        <div className="container">
          <div className="row justify-content-center position-relative">
            <div className="col-lg-10 col-xl-8">
              <img src={cover} alt={title} className="rounded" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <article className="article">
                <PostContent content={content} />


                {/*<p className="lead">
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                  sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
                <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
                  ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                </p>
                <h4>A heading to shift focus</h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia
                  animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
                  assumenda est, omnis dolor repellendus.
                </p>
                <figure>
                  <img src="assets/img/article-9.jpg" alt="Image" className="rounded" />
                  <figcaption>A caption to describe the image</figcaption>
                </figure>
                <p>
                  Aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                </p>
                <blockquote className="bg-primary-alt">
                  <div className="h3 mb-2">“We couldn’t have done it without the hard-working team from Leap.”</div>
                  <span className="text-small text-muted">– Harvey Dent (via Tareq I.)</span>
                  <a href="#" className="btn btn-primary btn-sm">
                    <img className="icon" src="assets/img/icons/social/twitter.svg" alt="twitter social icon" data-inject-svg />
                    <span>Tweet</span>
                  </a>
                </blockquote>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                  sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
                <h5>A minor heading to summarise</h5>
                <p>Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</p>
                <ul>
                  <li>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam</li>
                  <li>Corporis suscipit laboriosam</li>
                  <li>Aspernatur aut odit aut fugit eos qui ratione</li>
                  <li>Et quasi</li>
                </ul>*/}
              </article>
            </div>
          </div>
        </div>
      </section>



      {/*<h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      <img src={cover} alt={title} />
      <PostContent content={content} />
      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className='taglist'>
          {(tags && tags.length)
            ? tags.map(tag => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null
          }
        </ul>
      </div>
      <hr />*/}
    </div>
  )
}

export default ArticleTemplate
