import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {HTMLContent} from '../components/Content'
import ArticleTemplate from '../components/ArticleTemplate'
import SE0 from '../components/SEO'
import Share from '../components/Share'
import Disqus from '../components/Disqus'
import Layout from '../components/Layout'

import ReactSVG from 'react-svg'

const ArticlePage = ({data}) => {
  const {markdownRemark: post} = data
  return (
    <Layout>
      {/*<section className='section'>*/}
        <SE0
          title={post.frontmatter.title}
          meta_title={post.frontmatter.meta_title}
          meta_desc={post.frontmatter.meta_description}
          cover={post.frontmatter.cover}
          slug={post.fields.slug}
          date={post.frontmatter.date}
        />


      {/*<div className="article-progress" data-sticky="below-nav">
        <progress className="reading-position" value="0"></progress>
        <div className="article-progress-wrapper">
          <div className="container">
            <div className="row">
              <div className="col py-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <div className="text-small text-muted mr-1">Reading:</div>
                    <div className="text-small">Navigating the complexity of change aversion</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="text-small text-muted">Share:</span>
                    <div className="d-flex ml-1">
                      <a href="#" className="mx-1 btn btn-sm btn-round btn-primary">
                        <img className="icon" src="assets/img/icons/social/twitter.svg" alt="twitter social icon" data-inject-svg />
                      </a>
                      <a href="#" className="mx-1 btn btn-sm btn-round btn-primary">
                        <img className="icon" src="assets/img/icons/social/linkedin.svg" alt="linkedin social icon" data-inject-svg />
                      </a>
                      <a href="#" className="mx-1 btn btn-sm btn-round btn-primary">
                        <img className="icon" src="assets/img/icons/social/facebook.svg" alt="facebook social icon" data-inject-svg />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}


      <ArticleTemplate
        content={post.html}
        contentComponent={HTMLContent}
        cover={post.frontmatter.cover}
        meta_title={post.frontmatter.meta_title}
        meta_desc={post.frontmatter.meta_description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />


      
      <section className="has-divider">
        <div className="container pt-3">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <hr />
              <div className="d-flex align-items-center">
                <span className="text-small mr-1">Share this article:</span>
                <div className="d-flex mx-2">
                  <a href="#" className="btn btn-round btn-primary mx-1">
                    {/*<img className="icon icon-sm" src="assets/img/icons/social/twitter.svg" alt="twitter social icon" data-inject-svg />*/}
                    <ReactSVG
                      src={'img/icons/social/twitter.svg'} 
                      beforeInjection={svg => {
                        svg.classList.add('icon icon-sm')
                      }}
                    />
                  </a>
                  <a href="#" className="btn btn-round btn-primary mx-1">
                    {/*<img className="icon icon-sm" src="assets/img/icons/social/facebook.svg" alt="facebook social icon" data-inject-svg />*/}
                    <ReactSVG
                      src={'img/icons/social/facebook.svg'} 
                      beforeInjection={svg => {
                        svg.classList.add('icon icon-sm')
                      }}
                    />
                  </a>
                  <a href="#" className="btn btn-round btn-primary mx-1">
                    <img className="icon icon-sm" src="assets/img/icons/social/linkedin.svg" alt="linkedin social icon" data-inject-svg />
                  </a>
                </div>
              </div>
              <hr />
              
            </div>
          </div>
        </div>
        <div className="divider">
          <img className="bg-primary-alt" src="assets/img/dividers/divider-1.svg" alt="divider graphic" data-inject-svg />
        </div>
      </section>
      <section className="bg-primary-alt">
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <h3 className="h2">Related Stories</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="100">
              <a href="#" className="card card-body justify-content-between bg-primary text-light">
                <div className="d-flex justify-content-between mb-3">
                  <div className="text-small d-flex">
                    <div className="mr-2">
                      Links
                    </div>
                    <span className="opacity-70">19th December</span>
                  </div>
                  <span className="badge bg-primary-alt text-primary">
                    <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />27
                  </span>
                </div>
                <div>
                  <h2>A time-tracking app that isn’t a pain.</h2>
                  <span className="text-small opacity-70">http://www.website.io/link</span>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="200">
              <div className="card">
                <a href="#">
                  <img src="assets/img/article-1.jpg" alt="Image" className="card-img-top" />
                </a>
                <div className="card-body d-flex flex-column">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        <a href="#">Business</a>
                      </div>
                      <span className="text-muted">29th November</span>
                    </div>
                    <span className="badge bg-primary-alt text-primary">
                      <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />12
                    </span>
                  </div>
                  <a href="#">
                    <h4>How to build collateral</h4>
                  </a>
                  <p className="flex-grow-1">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                  </p>
                  <div className="d-flex align-items-center mt-3">
                    <img src="assets/img/avatars/female-3.jpg" alt="Image" className="avatar avatar-sm" />
                    <div className="ml-1">
                      <span className="text-small text-muted">By</span>
                      <span className="text-small">Abby Sims</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex" data-aos="fade-up" data-aos-delay="300">
              <div className="card">
                <a href="#">
                  <img src="assets/img/article-2.jpg" alt="Image" className="card-img-top" />
                </a>
                <div className="card-body d-flex flex-column">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="text-small d-flex">
                      <div className="mr-2">
                        <a href="#">Design</a>
                      </div>
                      <span className="text-muted">27th November</span>
                    </div>
                    <span className="badge bg-primary-alt text-primary">
                      <img className="icon icon-sm bg-primary" src="assets/img/icons/interface/heart.svg" alt="heart interface icon" data-inject-svg />23
                    </span>
                  </div>
                  <a href="#">
                    <h4>Forging your path</h4>
                  </a>
                  <p className="flex-grow-1">
                    Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
                  </p>
                  <div className="d-flex align-items-center mt-3">
                    <img src="assets/img/avatars/male-4.jpg" alt="Image" className="avatar avatar-sm" />
                    <div className="ml-1">
                      <span className="text-small text-muted">By</span>
                      <span className="text-small">Cedric</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        {/*<div className='container content'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <ArticleTemplate
                content={post.html}
                contentComponent={HTMLContent}
                cover={post.frontmatter.cover}
                meta_title={post.frontmatter.meta_title}
                meta_desc={post.frontmatter.meta_description}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
              />
              <Share
                title={post.frontmatter.title}
                slug={post.fields.slug}
                excerpt={post.frontmatter.meta_description}
              />
              <hr />
              <Disqus
                title={post.frontmatter.title}
                slug={post.fields.slug}
              />
            </div>
          </div>
        </div>*/}
      {/*</section>*/}
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
            slug
          }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cover
        meta_title
        meta_description
        tags
      }
    }
  }
`
